import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSignInByTokenStatus } from 'src/core/user/store/selectors';
import { signInByTokenReset } from 'src/core/user/store/actions';
import { signInByToken } from 'src/core/user/store/thunks';
import { parseQueryParams } from 'src/core/common/utils/query';

export function useSignInByToken(shouldReset = true) {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getSignInByTokenStatus);

  const doSignInByToken = useCallback(async () => {
    const { token } = parseQueryParams<{ token?: string }>();

    await dispatch(signInByToken(token));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (shouldReset) {
        dispatch(signInByTokenReset());
      }
    };
  }, [shouldReset, dispatch]);

  return {
    signInByToken: doSignInByToken,
    loadingStatus,
  };
}
